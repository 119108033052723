import React, { useState, useContext, useEffect } from "react";
import { useHttpRequest } from "../../../../hooks/httpRequest-hook";
import { useForm } from "../../../../hooks/form-hook";
import { AuthContext } from "../../../../context/auth-context";
import { ContractorContext } from "../../../../context/contractor-context";
import { isAlphanumeric, isRequired } from "../../../../utils/validators";
import Input from "../../../Input/Input";

// Components
import LoadingSpinner from "../../../../components/LoadingSpinner/LoadingSpinner";
import Modal from "../../../Modal/Modal";

import ListHeader from "../../../ListHeader/ListHeader";
import ActionBtn from "../../../Buttons/ActionBtn/ActionBtn";
import Iban from "../../Teacher/InvoicingTabs/IbanModal/IbanModal";
import GenerateInvoice from "../../../Tabs/Teacher/InvoicingTabs/GenerateInvoice/GenerateInvoice";

// Styles
import styles from "./ContractorInvoices.module.scss";

const ContractorInvoices = () => {

    // Auth Context
    const auth = useContext(AuthContext);

    // App context
    const context = useContext(ContractorContext);

    // Backend Request Hook
    const { isLoading, sendRequest } = useHttpRequest();

    // State of the modal
    const [modalStatus, setModalStatus] = useState(false);

    // Actual Modal View in use
    const [modalView, setModalView] = useState();

    // Courses in status = realized
    const [realizedCourses, setRealizedCourses] = useState();

    // filtered courses if campus is true
    const [filteredCourses, setFilteredCourses] = useState();

    // Courses in status = invoiced
    const [invoicedCourses, setInvoicedCourses] = useState();

    // Open Period for invoicing
    const [openToInvoice, setOpenToInvoice] = useState(false);

    // Next invoicing date
    const [newInvoicingDate, setNewInvoicingDate] = useState();
    
    // Next invoicing date
    const [todayDate] = useState(new Date().toISOString().split("T")[0]);

    const [campusChoice, setCampusChoice] = useState(false);

    const [monthChoice, setMonthChoice] = useState(false);

    const [month, setMonth] = useState(false);

    const [campus, setCampus] = useState(false);


    // Form State
    const [formState, inputHandler] = useForm(
        {
            campusSelector: {
                value: null,
                isValid: false,
            },
            monthSelector: {
                value: null,
                isValid: false,
            },
            invoiceNumber: {
                value: "",
                isValid: false,
            },
            invoiceDate: {
                value: "",
                isValid: false,
            },
            file: {
                value: null,
                isValid: false,
            }
        },
        false
    );

    const placeCampus = useState([
        {
            default: "",
            label: "0",
        },
        {
            default: "Campus de Paris Ouest",
            label: "74",
        },
        {
            default: "Campus de Lyon",
            label: "75",
        },
        {
            default: "Campus E-Learning",
            label: "76",
        },
        {
            default: "Campus de Bordeaux",
            label: "77",
        },
        {
            default: "Campus de Aix/Marseille",
            label: "78",
        },
        {
            default: "Campus de Lille",
            label: "79",
        },
        {
            default: "Campus de Nantes",
            label: "80",
        },
        {
            default: "Campus de Montpellier",
            label: "82",
        },
        {
            default: "Campus de Toulouse",
            label: "83",
        },
        {
            default: "Campus de Rennes",
            label: "84",
        },
        {
            default: "Campus de Strasbourg",
            label: "85",
        },
        {
            default: "Campus de Paris Est",
            label: "86",
        },
    ]);

    const months = useState([
        {
            default: "",
            label: "00",
        },
        {
            default: "Janvier",
            label: "01",
        },
        {
            default: "Février",
            label: "02",
        },
        {
            default: "Mars",
            label: "03",
        },
        {
            default: "Avril",
            label: "04",
        },
        {
            default: "Mai",
            label: "05",
        },
        {
            default: "Juin",
            label: "06",
        },
        {
            default: "Juillet",
            label: "07",
        },
        {
            default: "Août",
            label: "08",
        },
        {
            default: "Septembre",
            label: "09",
        },
        {
            default: "Octobre",
            label: "10",
        },
        {
            default: "Novembre",
            label: "11",
        },
        {
            default: "Décembre",
            label: "12",
        },
    ]);

    // Function helper to format campus list for invoicing

    const teacherCampusTab = [];
    if (context.teacherCampuses) {
        Object.entries(context.teacherCampuses).map(([key, value]) => {
            // We look at teacherCurrentAccountYear to filter on its values
            if (key === context.teacherCurrentAccountYear) {
                placeCampus[0].forEach(campus => {
                    for (const [key, valueOfCampus] of Object.entries(value)) {
                        if (valueOfCampus === campus.label)
                        teacherCampusTab.push(valueOfCampus);
                    }
                })
            }
        })
    }

    const filterTeacherCampusTab = (element) => {
        if (teacherCampusTab.includes(element.label) || element.label == "0") return true
        else return false;
    }

    const campusFormatForInvoicing = placeCampus[0].filter(filterTeacherCampusTab);
    
    

    // Function Helper
    const formatDate = (date) => {
        const datePart = date.split("-");
        return `${datePart[2]}/${datePart[1]}/${datePart[0]}`;
    };

    // function order invoices by Date
    const sortFunction = (array) => {
        return array.sort((a, b) => {
            
            if (a.teacherName != b.teacherName) {
                return a.teacherName.localeCompare(b.teacherName);
            }
            else return new Date(a.dateObject) - new Date(b.dateObject);
        }
    )}; 

    // Function Helper
    const structureCourseDuration = async (beginArray, endArray) => {

        // Calculate times
        const beginTime = parseFloat(beginArray[1]) / 60 + parseFloat(beginArray[0]);
        const endTime = parseFloat(endArray[1]) / 60 + parseFloat(endArray[0]);

        // Calculate total duration
        const duration = endTime - beginTime;
        const splitDuration = duration.toString().split(".");

        // Construct label
        const label = (splitDuration.length <= 1) ? `${duration}h` : `${splitDuration[0]}h${parseFloat(("0" + "." + splitDuration[1])) * 60}`;

        return { label, quantity: duration };
    };

    const structureInvoiceArray = async (filter, events, hourlyRate) => {


        // New Array Construction Variables
        let eventsIndex = [];
        let completedInvoices = [];
        
        for (let i = 0; i < events.length; i++) {
            let rate;

            for (const key in hourlyRate) {
                if (key == events[i].id) {
                    rate = parseInt(hourlyRate[key], 10)
                } 
            }
            // Skip all courses with New status
            if (events[i].status !== filter) continue;

            // Variables
            let oldDateIndex = new Date(
                `${events[i].begin_date}T${events[i].begin_time}`
            ).toISOString();;
            let dateIndex = (oldDateIndex + "_" + events[i].place_id + "_" + context.contractorTeachers[events[i].account_id].name);
            let eventsIndexNumber = eventsIndex.indexOf(dateIndex);
            const splitedBeginTime = events[i].begin_time.split(":");
            const splitedEndTime = events[i].end_time.split(":");
            const duration = await structureCourseDuration(splitedBeginTime, splitedEndTime);

            if (eventsIndexNumber === -1) {
                // if course doesn't exist in completedInvoices, create and add dateIndex and push object to array
                eventsIndex.push(dateIndex);
                completedInvoices.push({
                    composed_id: [events[i].id],
                    teacherName: context.contractorTeachers[events[i].account_id].name,
                    groups: [{ groupId: events[i].groups, courseId: events[i].id }],
                    place_id: events[i].place_id,
                    status: events[i].status,
                    title: events[i].course_label,
                    date: formatDate(events[i].begin_date),
                    dateObject: new Date(`${events[i].begin_date}T${events[i].begin_time}`),
                    hourLabel: `${splitedBeginTime[0]}:${splitedBeginTime[1]}h - ${splitedEndTime[0]}:${splitedEndTime[1]}h`,
                    quantity: duration.quantity,
                    durationLabel: duration.label,
                    rate: rate,
                    total: rate * duration.quantity * 100,
                });
            } else {
                completedInvoices[eventsIndexNumber].groups.push({
                    groupId: events[i].groups,
                    courseId: events[i].id,
                });
                completedInvoices[eventsIndexNumber].composed_id.push(events[i].id);
            }
            // await sortFunction(completedInvoices);
        }

        return completedInvoices;
    };

    // Fetch Calendar Events
    useEffect(() => {
        const fetchCoursesToInvoice = async () => {
            const beginDate = `2023-09-06`;
            const endDate = `2024-12-29`;
            const url = `${process.env.REACT_APP_API_HOST}/invoicing/teachers-to-invoice?begin=${beginDate}&end=${endDate}&account_id=${context.contractorMainAccount}&ids=${context.contractorTeachersIds.join()}`;
            try {
                const events = await sendRequest(url, "GET", null, {
                    Authorization: "Bearer " + auth.token,
                });

                const { courses, hourlyRate, invoiceDate } = events;

                if (invoiceDate == null || invoiceDate === "") setNewInvoicingDate(todayDate);
                else {
                    if (auth.userRole === "admin") setNewInvoicingDate(todayDate);
                    else setNewInvoicingDate(invoiceDate);
                } 

                // Treat and divide server data
                const coursesToBeInvoiced = await structureInvoiceArray(
                    "realized",
                    courses,
                    hourlyRate,
                );
                const coursesAlreadyInvoiced = await structureInvoiceArray(
                    "invoiced",
                    courses,
                    hourlyRate,
                );
                // Set data in the respective state
                setRealizedCourses(coursesToBeInvoiced);
                setInvoicedCourses(coursesAlreadyInvoiced);
            } catch (err) {
                console.error(err);
            }
        };
        fetchCoursesToInvoice();
    }, []);

    // Close invoicing period based on the last invoice date made
    useEffect(() => {
        // Define open period for invoicing
        if (!newInvoicingDate) return;
        if (new Date(newInvoicingDate).toISOString().split("T")[0] <= todayDate) setOpenToInvoice(true);
        else {
            setOpenToInvoice(false);
            return;
        }

    }, [newInvoicingDate, setNewInvoicingDate]);


    // UseEffect to filter the courses with the selected campus.
    useEffect(() => {
        let courses = [];
        let newCourses;

        if (campus && month && realizedCourses) {
            courses = [...realizedCourses];
            newCourses = courses.filter(c => (c.place_id == campus) && c.date.slice(3, 5) == month);
        } else {
            newCourses = realizedCourses;
        }

        if (newCourses) {
            console.log("sort")
            sortFunction(newCourses);
        }
        
        console.log(newCourses, "realizedCourses");
        setFilteredCourses(newCourses);
        setCampusChoice(false);
        setMonthChoice(false);
    }, [month])

    const fixScrollPosition = (status) => {
        if (status == false) {
            const scrollPosition = `${window.scrollY}px`;
            const body = document.body;
            body.style.position = "fixed";
            body.style.top = `-${scrollPosition}`;
        } else {
            const body = document.body;
            const scrollY = body.style.top;
            body.style.position = "";
            body.style.top = "";
            window.scrollTo(0, parseInt(scrollY || "0") * -1);
        }
    };

    const invoiceListModalHandler = (event) => {
        const targetId = event.currentTarget.id;
        if (modalStatus === false) {
            fixScrollPosition(modalStatus);
            setModalView(targetId);
            setModalStatus(true);
        } else {
            fixScrollPosition(modalStatus);
            setModalStatus(false);
        }
    };

    const ibanModalHandler = (event) => {
        const targetId = event.currentTarget.id;
        if (modalStatus === false) {
            fixScrollPosition(modalStatus);
            setModalView(targetId);
            setModalStatus(true);
        } else {
            fixScrollPosition(modalStatus);
            setModalStatus(false);
        }
    };

    let modalList = {
        invoiceList: (
            <GenerateInvoice
                modalFunction={invoiceListModalHandler}
                realizedCourses={campus ? filteredCourses : realizedCourses}
                setRealizedCourses={campus ? setFilteredCourses : setRealizedCourses}
                setInvoicedCourses={setInvoicedCourses}
                setNewInvoicingDate={setNewInvoicingDate}
                selfEmployed={true}
                campus={campus}
            />
        ),  
        createIban: (
            <Iban
                modalFunction={ibanModalHandler}
                texts={{
                    header: "ENREGISTRER MON IBAN",
                    title: "Saisir mon IBAN",
                    message: "Veuillez renseigner les informations ci-dessous :",
                    okMessage: "Votre IBAN a été bien enregistré.",
                }}
            />
        ),
    };

    // View variables
    let noInvoices = false;
 
    if (realizedCourses) if (realizedCourses.length === 0) noInvoices = true;

    return (
        <>
            {realizedCourses && (
                <Modal
                    show={modalStatus}
                    onClickFunction={invoiceListModalHandler}
                    overlay={modalList[modalView]}
                />
            )}
            {isLoading && (
                    <div className="spinner">
                        <LoadingSpinner />
                    </div>
                )}
            <article className={styles.container}>
                {!openToInvoice && newInvoicingDate && (
                    <section className={styles.subheader_wrapper}>
                        <ListHeader
                            title="Facturation fermée"
                            message={`Votre espace de facturation sera à nouveau disponible le ${formatDate(newInvoicingDate)}.`}
                        />
                    </section>
                )}
                {noInvoices && openToInvoice && (
                    <section className={styles.subheader_wrapper}>
                        <ListHeader
                            title="Vous n'avez pas de cours à facturer"
                            message="Pensez à bien fermer les émargements pour pouvoir créer une entrée de facturation."
                        />
                    </section>
                )}
                {realizedCourses && !noInvoices && openToInvoice && !campusChoice && !monthChoice && (
                    <section className={styles.subheader_wrapper}>
                        <ListHeader
                            title="Ajouter une entrée de facturation"
                            message="Pour continuer avec la facturation, veuillez cliquer sur le bouton ci-dessous :"
                        />
                        <ActionBtn
                            id="invoiceList"
                            btnType="contained"
                            btnStyle={styles.btnStyles}
                            activeBtnStyle={styles.btn_active}
                            btnText="Générer une facture"
                            textStyle={styles.btn_text}
                            onClick={(e) => {
                                if (context.teacherCurrentMultipleCampuses || auth.userType === "contractor") {
                                    setCampusChoice(true);
                                } else {
                                    invoiceListModalHandler(e);
                                }
                            }}                        
                        />
                    </section>
                )}
                {campusChoice && !monthChoice && (context.teacherCurrentMultipleCampuses || auth.userType === "contractor") && openToInvoice && (
                    <div className={styles.campusSelection}>
                    <h2 style={{color:"black"}}>Vous intervenez sur plusieurs de nos campus</h2>
                    <p>Veuillez sélectionner un campus dans la liste déroulante ci-dessous avant d'accéder à vos créneaux.</p>
                    <Input
                        id="campusSelector"
                        name="campusSelector"
                        typeOfInput="select"
                        type="text"
                        content={auth.userType === "contractor" ? placeCampus[0] : campusFormatForInvoicing}
                        validators={[isRequired()]}
                        errorText="Veuillez selectionner un campus"
                        initialValue={formState.inputs.campusSelector.value}
                        initialValid={formState.inputs.campusSelector.isValid}
                        onInput={inputHandler}
                        styles={styles.selectCampus}
                        />
                    <ActionBtn
                        id="invoiceList"
                        type="submit"
                        btnType="contained"
                        btnStyle={styles.btnStyles}
                        activeBtnStyle={styles.btn_active}
                        btnText="Valider"
                        textStyle={styles.btn_text}
                        onClick={(e) => {
                            // On setCampus sur le label du campus séléctionné
                            setCampus(formState.inputs.campusSelector.value);
                            setMonthChoice(true);
                            // invoiceListModalHandler(e);
                        }}
                        />
                </div>
            )}
            {monthChoice && (
                <div className={styles.campusSelection}>
                    <h2 style={{color:"black"}}>Vous intervenez sur plusieurs de nos campus</h2>
                    <p>Veuillez sélectionner un mois de facturation.</p>
                    <Input
                        id="monthSelector"
                        name="monthSelector"
                        typeOfInput="select"
                        type="text"
                        content={months[0]}
                        validators={[isRequired()]}
                        errorText="Veuillez selectionner un mois de facturation"
                        initialValue={formState.inputs.monthSelector.value}
                        initialValid={formState.inputs.monthSelector.isValid}
                        onInput={inputHandler}
                        styles={styles.selectCampus}
                    />
                    <ActionBtn
                        id="invoiceList"
                        type="submit"
                        btnType="contained"
                        btnStyle={styles.btnStyles}
                        activeBtnStyle={styles.btn_active}
                        btnText="Valider"
                        textStyle={styles.btn_text}
                        onClick={(e) => {
                            // On setCampus sur le label du campus séléctionné
                            setMonth(formState.inputs.monthSelector.value);
                            invoiceListModalHandler(e);
                        }}
                    />
                </div>

            )}
                {/* {!context.teacherCanInvoice && realizedCourses && !noInvoices && openToInvoice && (
                    <section className={styles.subheader_wrapper}>
                        <ListHeader
                            title="Votre IBAN n'est pas encore renseigné"
                            message="Pour continuer avec la facturation, veuillez saisir votre IBAN en cliquant ci-dessous :"
                        />
                        <ActionBtn
                            id="createIban"
                            btnType="contained"
                            btnStyle={styles.btnStyles}
                            activeBtnStyle={styles.btn_active}
                            btnText="Je saisis mon IBAN"
                            textStyle={styles.btn_text}
                            onClick={ibanModalHandler}
                        />
                    </section>
                )} */}
            </article>
        </>
    );
};

export default ContractorInvoices;
