/****************************
 * COMMON DATA DICTIONARIES *
 ****************************/

export const gemaSchools = ["esi", "ia-school", "cyber"];

export const studentRythme = ["part_time", "full_time"];

export const AcademicLevels = ["b1", "b2", "b3", "m1", "m2", "mba_1", "cesur_y"];

export const AcademicLabelsForLevels = {
    '1st': "Bachelor 1",
    '2nd': "Bachelor 2",
    '3rd': "Bachelor 3",
    '4th': "Mastère 1",
    '5th': "Mastère 2",
    'mba_1': "MBA",
    'cesur_y': "Césure"
}

export const campusIds = ["74", "1_paris", "75", "2_lyon", "76", "3_online", "77", "4_bordeaux", "78", "5_aix_marseille", "79", "6_lille", "80", "7_nantes", "82", "8_montpellier", "83", "9_toulouse", "84", "10_rennnes", "85", "11_strasbourg", "86", "12_paris_est"];

export const monthsLabel = [
    { month: "01", value: "jan" },
    { month: "02", value: "fév" },
    { month: "03", value: "mar" },
    { month: "04", value: "avr" },
    { month: "05", value: "mai" },
    { month: "06", value: "jun" },
    { month: "07", value: "jui" },
    { month: "08", value: "août" },
    { month: "09", value: "sep" },
    { month: "10", value: "oct" },
    { month: "11", value: "nov" },
    { month: "12", value: "déc" },
];

export const gradeLabels = {
    "4.00": {
        eval_label: "A (Excellent)",
        notes_label: "A (4)",
    },
    "3.50": {
        eval_label: "B (Très Bien)",
        notes_label: "B (3.5)",
    },
    "3.00": {
        eval_label: "C (Bien)",
        notes_label: "C (3)",
    },
    "2.50": {
        eval_label: "D (Assez bien)",
        notes_label: "D (2.5)",
    },
    "2.00": {
        eval_label: "E (Passable)",
        notes_label: "E (2)",
    },
    "1.00": {
        eval_label: "F (Insuffisant)",
        notes_label: "F (1)",
    },
    "0.00": {
        eval_label: "FX (Très insuffisant)",
        notes_label: "FX (0)",
    },
    null: {
        eval_label: "NE (Non-évalué)",
        notes_label: "Non-évalué",
    },
};

export const campusData = {
    0: {
        city: "",
        mainLabel: "",
        planningLabel: "",
    },
    74: {
        city: "Paris Ouest",
        mainLabel: "Campus de Paris Ouest",
        planningLabel: "(Campus de Paris Ouest)",
        entryDates: {
            s1: {
                esi: {
                    b1: "",
                    b2: "",
                    b3: "",
                    m1: "",
                    m2: "",
                    mba_1: "",
                },
                "ia-school": {
                    b1: "",
                    b2: "",
                    b3: "",
                    m1: "",
                    m2: "",
                },
                "cyber": {
                    b1: "",
                    b2: "",
                    b3: "",
                    m1: "",
                    m2: "",
                },
            },
            s2: {
                esi: {
                    b1: "",
                    b2: "",
                    b3: "",
                    m1: "",
                    m2: "",
                    mba_1: "",
                },
                "ia-school": {
                    b1: "",
                    b2: "",
                    b3: "",
                    m1: "",
                    m2: "",
                },
                "cyber": {
                    b1: "",
                    b2: "",
                    b3: "",
                    m1: "",
                    m2: "",
                },
            },
        },
    },
    75: {
        city: "Lyon",
        mainLabel: "Campus de Lyon",
        planningLabel: "(Campus de Lyon)",
        entryDates: {
            s1: {
                esi: {
                    b1: "",
                    b2: "",
                    b3: "",
                    m1: "",
                    m2: "",
                    mba_1: "",
                },
                "ia-school": {
                    b1: "",
                    b2: "",
                    b3: "",
                    m1: "",
                    m2: "",
                },
                "cyber": {
                    b1: "",
                    b2: "",
                    b3: "",
                    m1: "",
                    m2: "",
                },
            },
            s2: {
                esi: {
                    b1: "",
                    b2: "",
                    b3: "",
                    m1: "",
                    m2: "",
                    mba_1: "",
                },
                "ia-school": {
                    b1: "",
                    b2: "",
                    b3: "",
                    m1: "",
                    m2: "",
                },
                "cyber": {
                    b1: "",
                    b2: "",
                    b3: "",
                    m1: "",
                    m2: "",
                },
            },
        },
    },
    76: {
        city: "E-Learning",
        mainLabel: "Campus E-Learning",
        planningLabel: "(Campus E-Learning)",
        entryDates: {
            s1: {
                esi: {
                    b1: "",
                    b2: "",
                    b3: "",
                    m1: "",
                    m2: "",
                    mba_1: "",
                },
                "ia-school": {
                    b1: "",
                    b2: "",
                    b3: "",
                    m1: "",
                    m2: "",
                },
                "cyber": {
                    b1: "",
                    b2: "",
                    b3: "",
                    m1: "",
                    m2: "",
                },
            },
            s2: {
                esi: {
                    b1: "",
                    b2: "",
                    b3: "",
                    m1: "",
                    m2: "",
                    mba_1: "",
                },
                "ia-school": {
                    b1: "",
                    b2: "",
                    b3: "",
                    m1: "",
                    m2: "",
                },
                "cyber": {
                    b1: "",
                    b2: "",
                    b3: "",
                    m1: "",
                    m2: "",
                },
            },
        },
    },
    77: {
        city: "Bordeaux",
        mainLabel: "Campus de Bordeaux",
        planningLabel: "(Campus de Bordeaux)",
        entryDates: {
            s1: {
                esi: {
                    b1: "",
                    b2: "",
                    b3: "",
                    m1: "",
                    m2: "",
                    mba_1: "",
                },
                "ia-school": {
                    b1: "",
                    b2: "",
                    b3: "",
                    m1: "",
                    m2: "",
                },
                 "cyber": {
                    b1: "",
                    b2: "",
                    b3: "",
                    m1: "",
                    m2: "",
                },
            },
            s2: {
                esi: {
                    b1: "",
                    b2: "",
                    b3: "",
                    m1: "",
                    m2: "",
                    mba_1: "",
                },
                "ia-school": {
                    b1: "",
                    b2: "",
                    b3: "",
                    m1: "",
                    m2: "",
                },
                 "cyber": {
                    b1: "",
                    b2: "",
                    b3: "",
                    m1: "",
                    m2: "",
                },
            },
        },
    },
    78: {
        city: "Aix-Marseille",
        mainLabel: "Campus de Aix-Marseille",
        planningLabel: "(Campus de Aix-Marseille)",
        entryDates: {
            s1: {
                esi: {
                    b1: "",
                    b2: "",
                    b3: "",
                    m1: "",
                    m2: "",
                    mba_1: "",
                },
                "ia-school": {
                    b1: "",
                    b2: "",
                    b3: "",
                    m1: "",
                    m2: "",
                },
                "cyber": {
                    b1: "",
                    b2: "",
                    b3: "",
                    m1: "",
                    m2: "",
                },
            },
            s2: {
                esi: {
                    b1: "",
                    b2: "",
                    b3: "",
                    m1: "",
                    m2: "",
                    mba_1: "",
                },
                "ia-school": {
                    b1: "",
                    b2: "",
                    b3: "",
                    m1: "",
                    m2: "",
                },
                "cyber": {
                    b1: "",
                    b2: "",
                    b3: "",
                    m1: "",
                    m2: "",
                },
            },
        },
    },
    79: {
        city: "Lille",
        mainLabel: "Campus de Lille",
        planningLabel: "(Campus de Lille)",
        entryDates: {
            s1: {
                esi: {
                    b1: "",
                    b2: "",
                    b3: "",
                    m1: "",
                    m2: "",
                    mba_1: "",
                },
                "ia-school": {
                    b1: "",
                    b2: "",
                    b3: "",
                    m1: "",
                    m2: "",
                },
                "cyber": {
                    b1: "",
                    b2: "",
                    b3: "",
                    m1: "",
                    m2: "",
                },
            },
            s2: {
                esi: {
                    b1: "",
                    b2: "",
                    b3: "",
                    m1: "",
                    m2: "",
                    mba_1: "",
                },
                "ia-school": {
                    b1: "",
                    b2: "",
                    b3: "",
                    m1: "",
                    m2: "",
                },
                "cyber": {
                    b1: "",
                    b2: "",
                    b3: "",
                    m1: "",
                    m2: "",
                },
            },
        },
    },
    80: {
        city: "Nantes",
        mainLabel: "Campus de Nantes",
        planningLabel: "(Campus de Nantes)",
        entryDates: {
            s1: {
                esi: {
                    b1: "",
                    b2: "",
                    b3: "",
                    m1: "",
                    m2: "",
                    mba_1: "",
                },
                "ia-school": {
                    b1: "",
                    b2: "",
                    b3: "",
                    m1: "",
                    m2: "",
                },
                "cyber": {
                    b1: "",
                    b2: "",
                    b3: "",
                    m1: "",
                    m2: "",
                },
            },
            s2: {
                esi: {
                    b1: "",
                    b2: "",
                    b3: "",
                    m1: "",
                    m2: "",
                    mba_1: "",
                },
                "ia-school": {
                    b1: "",
                    b2: "",
                    b3: "",
                    m1: "",
                    m2: "",
                },
                "cyber": {
                    b1: "",
                    b2: "",
                    b3: "",
                    m1: "",
                    m2: "",
                },
            },
        },
    },
    82: {
        city: "Montpellier",
        mainLabel: "Campus de Montpellier",
        planningLabel: "(Campus de Montpellier)",
        entryDates: {
            s1: {
                esi: {
                    b1: "",
                    b2: "",
                    b3: "",
                    m1: "",
                    m2: "",
                    mba_1: "",
                },
                "ia-school": {
                    b1: "",
                    b2: "",
                    b3: "",
                    m1: "",
                    m2: "",
                },
                "cyber": {
                    b1: "",
                    b2: "",
                    b3: "",
                    m1: "",
                    m2: "",
                },
            },
            s2: {
                esi: {
                    b1: "",
                    b2: "",
                    b3: "",
                    m1: "",
                    m2: "",
                    mba_1: "",
                },
                "ia-school": {
                    b1: "",
                    b2: "",
                    b3: "",
                    m1: "",
                    m2: "",
                },
                "cyber": {
                    b1: "",
                    b2: "",
                    b3: "",
                    m1: "",
                    m2: "",
                },
            },
        },
    },
    83: {
        city: "Toulouse",
        mainLabel: "Campus de Toulouse",
        planningLabel: "(Campus de Toulouse)",
        entryDates: {
            s1: {
                esi: {
                    b1: "",
                    b2: "",
                    b3: "",
                    m1: "",
                    m2: "",
                    mba_1: "",
                },
                "ia-school": {
                    b1: "",
                    b2: "",
                    b3: "",
                    m1: "",
                    m2: "",
                },
                "cyber": {
                    b1: "",
                    b2: "",
                    b3: "",
                    m1: "",
                    m2: "",
                },
            },
            s2: {
                esi: {
                    b1: "",
                    b2: "",
                    b3: "",
                    m1: "",
                    m2: "",
                    mba_1: "",
                },
                "ia-school": {
                    b1: "",
                    b2: "",
                    b3: "",
                    m1: "",
                    m2: "",
                },
                "cyber": {
                    b1: "",
                    b2: "",
                    b3: "",
                    m1: "",
                    m2: "",
                },
            },
        },
    },
    84: {
        city: "Rennes",
        mainLabel: "Campus de Rennes",
        planningLabel: "(Campus de Rennes)",
        entryDates: {
            s1: {
                esi: {
                    b1: "",
                    b2: "",
                    b3: "",
                    m1: "",
                    m2: "",
                    mba_1: "",
                },
                "ia-school": {
                    b1: "",
                    b2: "",
                    b3: "",
                    m1: "",
                    m2: "",
                },
                "cyber": {
                    b1: "",
                    b2: "",
                    b3: "",
                    m1: "",
                    m2: "",
                },
            },
            s2: {
                esi: {
                    b1: "",
                    b2: "",
                    b3: "",
                    m1: "",
                    m2: "",
                    mba_1: "",
                },
                "ia-school": {
                    b1: "",
                    b2: "",
                    b3: "",
                    m1: "",
                    m2: "",
                },
                "cyber": {
                    b1: "",
                    b2: "",
                    b3: "",
                    m1: "",
                    m2: "",
                },
            },
        },
    },
    85: {
        city: "Strasbourg",
        mainLabel: "Campus de Strasbourg",
        planningLabel: "(Campus de Strasbourg)",
        entryDates: {
            s1: {
                esi: {
                    b1: "",
                    b2: "",
                    b3: "",
                    m1: "",
                    m2: "",
                    mba_1: "",
                },
                "ia-school": {
                    b1: "",
                    b2: "",
                    b3: "",
                    m1: "",
                    m2: "",
                },
                "cyber": {
                    b1: "",
                    b2: "",
                    b3: "",
                    m1: "",
                    m2: "",
                },
            },
            s2: {
                esi: {
                    b1: "",
                    b2: "",
                    b3: "",
                    m1: "",
                    m2: "",
                    mba_1: "",
                },
                "ia-school": {
                    b1: "",
                    b2: "",
                    b3: "",
                    m1: "",
                    m2: "",
                },
                "cyber": {
                    b1: "",
                    b2: "",
                    b3: "",
                    m1: "",
                    m2: "",
                },
            },
        },
    },
    86: {
        city: "Paris Est",
        mainLabel: "Campus de Paris Est",
        planningLabel: "(Campus de Paris Est)",
        entryDates: {
            s1: {
                esi: {
                    b1: "",
                    b2: "",
                    b3: "",
                    m1: "",
                    m2: "",
                    mba_1: "",
                },
                "ia-school": {
                    b1: "",
                    b2: "",
                    b3: "",
                    m1: "",
                    m2: "",
                },
                "cyber": {
                    b1: "",
                    b2: "",
                    b3: "",
                    m1: "",
                    m2: "",
                },
            },
            s2: {
                esi: {
                    b1: "",
                    b2: "",
                    b3: "",
                    m1: "",
                    m2: "",
                    mba_1: "",
                },
                "ia-school": {
                    b1: "",
                    b2: "",
                    b3: "",
                    m1: "",
                    m2: "",
                },
                "cyber": {
                    b1: "",
                    b2: "",
                    b3: "",
                    m1: "",
                    m2: "",
                },
            },
        },
    },
};

export const frenchDepartments = [
    { default: "", label: ""},
    { default: "France Métropolitaine", label: "109" },
    { default: "Uniquement ma ville d'habitation ou alentours de mon campus", label: "110" },
    { default: "01 - Ain", label: "1" },
    { default: "02 - Aisne", label: "2" },
    { default: "03 - Allier", label: "3" },
    { default: "04 - Alpes-de-Haute-Provence", label: "4" },
    { default: "05 - Hautes-Alpes", label: "5" },
    { default: "06 - Alpes-Maritimes", label: "6" },
    { default: "07 - Ardèche", label: "7" },
    { default: "08 - Ardennes", label: "8" },
    { default: "09 - Ariège", label: "9" },
    { default: "10 - Aube", label: "10" },
    { default: "11 - Aude", label: "11" },
    { default: "12 - Aveyron", label: "12" },
    { default: "13 - Bouches-du-Rhône", label: "13" },
    { default: "14 - Calvados", label: "14" },
    { default: "15 - Cantal", label: "15" },
    { default: "16 - Charente", label: "16" },
    { default: "17 - Charente-Maritime", label: "17" },
    { default: "18 - Cher", label: "18" },
    { default: "19 - Corrèze", label: "19" },
    { default: "2A - Corse-du-Sud", label: "2A" },
    { default: "2B - Haute-Corse", label: "2B" },
    { default: "21 - Côte-d'Or", label: "21" },
    { default: "22 - Côtes-d'Armor", label: "22" },
    { default: "23 - Creuse", label: "23" },
    { default: "24 - Dordogne", label: "24" },
    { default: "25 - Doubs", label: "25" },
    { default: "26 - Drôme", label: "26" },
    { default: "27 - Eure", label: "27" },
    { default: "28 - Eure-et-Loir", label: "28" },
    { default: "29 - Finistère", label: "29" },
    { default: "30 - Gard", label: "30" },
    { default: "31 - Haute-Garonne", label: "31" },
    { default: "32 - Gers", label: "32" },
    { default: "33 - Gironde", label: "33" },
    { default: "34 - Hérault", label: "34" },
    { default: "35 - Ille-et-Vilaine", label: "35" },
    { default: "36 - Indre", label: "36" },
    { default: "37 - Indre-et-Loire", label: "37" },
    { default: "38 - Isère", label: "38" },
    { default: "39 - Jura", label: "39" },
    { default: "40 - Landes", label: "40" },
    { default: "41 - Loir-et-Cher", label: "41" },
    { default: "42 - Loire", label: "42" },
    { default: "43 - Haute-Loire", label: "43" },
    { default: "44 - Loire-Atlantique", label: "44" },
    { default: "45 - Loiret", label: "45" },
    { default: "46 - Lot", label: "46" },
    { default: "47 - Lot-et-Garonne", label: "47" },
    { default: "48 - Lozère", label: "48" },
    { default: "49 - Maine-et-Loire", label: "49" },
    { default: "50 - Manche", label: "50" },
    { default: "51 - Marne", label: "51" },
    { default: "52 - Haute-Marne", label: "52" },
    { default: "53 - Mayenne", label: "53" },
    { default: "54 - Meurthe-et-Moselle", label: "54" },
    { default: "55 - Meuse", label: "55" },
    { default: "56 - Morbihan", label: "56" },
    { default: "57 - Moselle", label: "57" },
    { default: "58 - Nièvre", label: "58" },
    { default: "59 - Nord", label: "59" },
    { default: "60 - Oise", label: "60" },
    { default: "61 - Orne", label: "61" },
    { default: "62 - Pas-de-Calais", label: "62" },
    { default: "63 - Puy-de-Dôme", label: "63" },
    { default: "64 - Pyrénées-Atlantiques", label: "64" },
    { default: "65 - Hautes-Pyrénées", label: "65" },
    { default: "66 - Pyrénées-Orientales", label: "66" },
    { default: "67 - Bas-Rhin", label: "67" },
    { default: "68 - Haut-Rhin", label: "68" },
    { default: "69 - Rhône", label: "69" },
    { default: "70 - Haute-Saône", label: "70" },
    { default: "71 - Saône-et-Loire", label: "71" },
    { default: "72 - Sarthe", label: "72" },
    { default: "73 - Savoie", label: "73" },
    { default: "74 - Haute-Savoie", label: "74" },
    { default: "75 - Paris", label: "75" },
    { default: "76 - Seine-Maritime", label: "76" },
    { default: "77 - Seine-et-Marne", label: "77" },
    { default: "78 - Yvelines", label: "78" },
    { default: "79 - Deux-Sèvres", label: "79" },
    { default: "80 - Somme", label: "80" },
    { default: "81 - Tarn", label: "81" },
    { default: "82 - Tarn-et-Garonne", label: "82" },
    { default: "83 - Var", label: "83" },
    { default: "84 - Vaucluse", label: "84" },
    { default: "85 - Vendée", label: "85" },
    { default: "86 - Vienne", label: "86" },
    { default: "87 - Haute-Vienne", label: "87" },
    { default: "88 - Vosges", label: "88" },
    { default: "89 - Yonne", label: "89" },
    { default: "90 - Territoire de Belfort", label: "90" },
    { default: "91 - Essonne", label: "91" },
    { default: "92 - Hauts-de-Seine", label: "92" },
    { default: "93 - Seine-Saint-Denis", label: "93" },
    { default: "94 - Val-de-Marne", label: "94" },
    { default: "95 - Val-d'Oise", label: "95" },
    { default: "971 - Guadeloupe", label: "96" },
    { default: "972 - Martinique", label: "97" },
    { default: "973 - Guyane", label: "98" },
    { default: "974 - La Réunion", label: "99" },
    { default: "975 - Saint-Pierre-et-Miquelon", label: "100" },
    { default: "976 - Mayotte", label: "101" },
    { default: "977 - Saint-Barthélemy", label: "102" },
    { default: "978 - Saint-Martin", label: "103" },
    { default: "984 - Terres Australes et Antarctiques Françaises", label: "104" },
    { default: "986 - Wallis-et-Futuna", label: "105" },
    { default: "987 - Polynésie Française", label: "106" },
    { default: "988 - Nouvelle-Calédonie", label: "107" },
    { default: "989 - Île de Clipperton", label: "108" }
];